import * as React from 'react';

import AttributeGrid from '../components/AttributeGrid';
import Container from '../components/Container';
import Hero from '../components/Hero';
import Highlight from '../components/Highlight';
import Layout from '../components/Layout/Layout';
import Quote from '../components/Quote';
import Title from '../components/Title';
import ProductCollectionGrid from '../components/ProductCollectionGrid';

import * as styles from './index.module.css';
import { navigate } from 'gatsby';

const IndexPage = () => {
  const goToShop = () => {
    navigate('/selection');
  };

  return (
    <Layout disablePaddingBottom>
      {/* Hero Container */}
      <Hero
        maxWidth={'500px'}
        image={'/vending-machines/vending-machine-night.webp'}
        title={`Vending Machines that Deliver`}
        subtitle={'The Best Choice for Your Business.'}
        ctaText={'View selection'}
        ctaAction={goToShop}
      />

      {/* Message Container */}
      <div className={styles.messageContainer}>
        <p>
          Fuel your cravings with convenience - explore our vending machine{' '}
        </p>
        <p> delights at the touch of a button!</p>
      </div>

      {/* Collection Container */}
      <div className={styles.collectionContainer}>
        <Container size={'large'}>
          <Title name={'Savory Collection'} />
          <ProductCollectionGrid />
        </Container>
      </div>

      {/* Highlight  */}
      {/* <div className={styles.highlightContainer}>
        <Container size={'large'} fullMobile>
          <Highlight
            //image={'/vending-machines/woman-buying-snack.jpeg'}
            //image={'/vending-machines/woman-buying-snack.webp'}
            //image={'/svgs/snack-illustration.svg'}
            altImage={'highlight image'}
            miniImage={'/vending-machines/vending-machine-set.webp'}
            miniImageAlt={'mini highlight image'}
            title={'Indulge in On-the-Go Convenience'}
            description={`With our wide selection of vending machine treats.`}
            textLink={'Our selection'}
            link={'/selection'}
          />
        </Container>
      </div> */}

      {/* Promotion */}
      <div className={styles.promotionContainer}>
        <Hero
          image={'/vending-machines/woman-paying-for-product.webp'}
          title={`Dependable. Professional. Successful.`}
        />
      </div>

      {/* Quote */}
      <Quote
        bgColor={'var(--standard-light-grey)'}
        title={`About John Jon's Vending`}
        quote={`“At John Jons Vending, it's our mission to provide the best quality vending machines and snack services to 
        businesses across the country.”`}
      />

      {/* <AttributeGrid /> */}
    </Layout>
  );
};

export default IndexPage;

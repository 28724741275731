import React from 'react';
import * as styles from './ProductCollectionGrid.module.css';

import ProductCollection from '../ProductCollection';

const ProductCollectionGrid = (props) => {
  return (
    <div className={styles.root}>
      <ProductCollection
        image={'/collections/chips-and-crisps.webp'}
        title={'Chips & Crisps'}
        text={'VIEW NOW'}
        link={'/selection'}
      />
      <ProductCollection
        image={'/collections/candy-and-chocolate.webp'}
        title={'Candy & Chocolate'}
        text={'VIEW NOW'}
        link={'/selection'}
      />
      <ProductCollection
        image={'/collections/cookies-and-baked-goods.webp'}
        title={'Cookies & Baked Goods'}
        text={'VIEW NOW'}
        link={'/selection'}
      />
      <ProductCollection
        image={'/collections/beverages.webp'}
        title={'Soft Drinks & Refreshments'}
        text={'VIEW NOW'}
        link={'/selection'}
      />
    </div>
  );
};

export default ProductCollectionGrid;
